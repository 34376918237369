import { AnswerType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import { GenericData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '~/components/List/Item/Answer';
import CMSView from '~/components/View';
import List from '~/containers/Espace/List';
import extract from '~/params/note.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';
import useQuestions from '~/utils/useQuestions';

const PageAnswers: FC<
  PageProps & EspaceProps & UserProps & { pageContext: { tab?: number } }
> = ({ espace, location, pageContext: { tab }, user }) => {
  const { t } = useTranslation();
  const items = useQuestions({
    espaceId: espace.id,
    pathname: location.pathname,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          itemList={Item}
          model={
            new GenericData<AnswerType>({
              collectionName: 'answers',
              espaceId: espace.id,
              orderField: 'createdAt',
              params: extract,
              wheres: {
                parent: '',
              },
            })
          }
          removeAddButton
          search={location.search}
          subMenu={items}
          tabIndex={tab ?? 0}
          title={t('questions.title')}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageAnswers);
