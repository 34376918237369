import { ActionProps } from '~/components/Actions/props';
import IconBallotCheck from '~/icons/BallotCheck';
import IconQuestionCircle from '~/icons/QuestionCircle';

const useQuestions = ({
  espaceId,
  pathname,
}: {
  espaceId: string;
  pathname: string;
}): (ActionProps | ActionProps[])[] | undefined => {
  const isActive = (to: string, strict?: boolean): string | undefined => {
    if (strict) {
      return `/espaces/${espaceId}/questions${to}` === pathname
        ? 'is-active'
        : undefined;
    }

    return pathname.slice(0, `/espaces/${espaceId}/questions${to}`.length) ===
      `/espaces/${espaceId}/questions${to}`
      ? 'is-active'
      : undefined;
  };

  return [
    {
      className: isActive(``, true),
      icon: IconQuestionCircle,
      label: 'Questions',
      to: `/espaces/${espaceId}/questions`,
    },
    {
      className: isActive(`/answers`),
      icon: IconBallotCheck,
      label: 'Réponses',
      to: `/espaces/${espaceId}/questions/answers`,
    },
  ];
};

export default useQuestions;
